<template>
  <div class="page">
    <div class="create">
      <!-- <el-button plain type="normal" size="mini" @click="close">返回</el-button> -->
      <el-button plain type="primary" size="mini" @click="print">打印工单</el-button>
    </div>
    <el-row class="outer-row" id="print">
      <el-col :span="8">
        <div class="pdl20div">
          <el-descriptions title="设备信息" :column="1">
            <el-descriptions-item label="设备名称"
              >{{device.equipmentName}}</el-descriptions-item
            >
            <el-descriptions-item label="设备类型"
              >{{device.equipmentTypeName}}</el-descriptions-item
            >
            <el-descriptions-item label="规格型号">{{device.equipmentSpecifications}}</el-descriptions-item>
            <el-descriptions-item label="设备状态">{{startName(device.equipmentStatus)}}</el-descriptions-item>
            <el-descriptions-item label="生产日期">{{device.equipmentTime}}</el-descriptions-item>
            <el-descriptions-item label="使用日期">{{device.equipmentUseTime}}</el-descriptions-item>
            <el-descriptions-item label="所在街道">{{device.equipmentStreet}}</el-descriptions-item>
            <el-descriptions-item label="生产厂家">{{device.equipmentProducers}}</el-descriptions-item>
            <el-descriptions-item label="关联管线"> {{device.lineName}}</el-descriptions-item>
            <el-descriptions-item label="所在地址">{{device.equipmentHome}}</el-descriptions-item>
          </el-descriptions>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="right-part">
          <div class="title">采集信息</div>
          <div class="right-content">
            <el-popover width="300" @show="detailAddress(deviceLoacnion,'container')" style="float:right;margin-right: 40px;">
                    <div :id="'container'" style="height:300px;width:300px"></div>
                    <el-button type="primary" plain  size="mini" slot="reference">打卡位置</el-button>
                  </el-popover>
                <!-- <el-button type="primary" plain  size="mini" style="float:right;margin-right: 40px;" @click="detailAddress(device.location,'container')">打卡位置</el-button> -->
                  <div>
                    <el-descriptions direction='vertical' :column="1" v-for="(item1,second) in form" :key="second">
                      <el-descriptions-item :label="'● '+item1.itemName" :label-style="{width:'120px',color:'#444444',fontWeight: 'bold',borderBottom:'8px'}">
                        <!-- 父级内容判断类型为文本框直接显示 -->
                        <template v-if="item1.inputType==1 || item1.inputType==5" >{{item1.note}}</template>
                        <!-- 判断类型为2单选 3多选显示 -->
                        <div v-if="item1.inputType==2||item1.inputType==3">
                          <!-- 有子集的情况循环展示 -->
                          <div v-for="item2 in item1.items" :key="'b'+item2.items" style="position: relative">
                            <!-- 判断是否选中 选中展示 -->
                            <template v-if="item2.isPitchUp">
                              <div class="isPitchUpImg">
                              <!-- 显示小图标，1正常 2异常 -->
                              <img class="icon-img" :src="item2.type==1?require('../../assets/normal1.png'):require('../../assets/warning1.png')" />
                              <!-- 选项内容 -->
                              {{item2.content}}
                              </div>
                              <!-- 补充内容存在，显示补充内容 -->
                              <div class="describ" v-if="item2.note" style="min-width: 180px">
                                {{item2.note}}
                              </div>
                              <!-- 判断检查项照片url存在展示照片 -->
                              <div class="imgContain" v-if="item2.url">
                                <!-- 多个照片循环展示 -->
                                <!-- <img style="width: 100px; height: 100px" :src="fourths" 
                                v-for="(fourths,int4) in item2.url.split(',')" 
                                @click="showImg(fourths)"
                                :key="int4" alt=""/> -->
                                <span v-for="(fourths,int4) in item2.url.split(',')" :key="int4">
                                <el-image
                                  style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                                  :src="fourths"
                                  :preview-src-list="item2.url.split(',')"
                                >
                                </el-image>
                                </span>
                              </div>
                              <!-- 关联项存在展示 -->
                                <el-descriptions direction='vertical' :column="1" v-for="item3 in item2.junior" :key="item3.juniorId" class="relevance">
                                  <!-- 采集项名称 -->
                                  <el-descriptions-item :label-style="{color:'#444444',fontWeight: 'bold'}"
                                          :label="'● '+item3.juniorName">
                                    <!-- 录入内容为文本框 -->
                                    <template v-if="item3.juniorInputType==1 || item3.juniorInputType==5">{{item3.note}}</template>
                                    <!-- 录入内容为单选多选 -->
                                    <div v-if="item3.juniorInputType==2||item3.juniorInputType==3">
                                      <!-- 采集子项关联项的子项存在的情况 -->
                                      <div v-for="item4 in item3.juniors" :key="'b'+item4.juniorsId">
                                        <!-- 是否选中 -->
                                        <template  v-if="item4.isPitchUp">
                                          <div class="isPitchUpImg">
                                          <!-- 分类是正常还是异常 分类 1正常 2异常 -->
                                          <img class="icon-img" :src="item4.juniorsType==1?require('../../assets/normal1.png'):require('../../assets/warning1.png')" />
                                          	<!-- 选项内容 -->
                                          {{item4.juniorsContent}} 
                                          </div>
                                          <!-- 补充内容是否存在 展示 -->
                                          <div class="describ" v-if="item4.note" style="min-width: 180px">
                                            {{item4.note}}
                                          </div>
                                          <!-- 检查照片是否存在 -->
                                          <div class="imgContain" v-if="item4.url">
                                            <!-- <img style="width: 100px; height: 100px" :src="firsts" 
                                            v-for="(firsts,int1) in item4.url.split(',')" 
                                             @click="showImg(firsts)"
                                            :key="int1" alt=""/> -->
                                            <span v-for="(firsts,int1) in item4.url.split(',')" :key="int1">
                                            <el-image
                                              style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                                              :src="firsts"
                                              :preview-src-list="item4.url.split(',')"
                                            >
                                            </el-image>
                                            </span>
                                          </div>
                                        </template>
                                      </div>
                                    </div>
                                    <!-- 子项为4上传照片情况 -->
                                    <div v-if="item3.juniorInputType==4">
                                      <!-- 循环展示多个图片 -->
                                      <div class="imgContain" v-if="item3.url">
                                        <!-- <img style="width: 100px; height: 100px" :src="sectids" 
                                        v-for="(sectids,int2) in item3.url.split(',')" 
                                        @click="showImg(sectids)"
                                        :key="int2" alt=""/> -->
                                        <span v-for="(sectids,int2) in item3.url.split(',')" :key="int2">
                                            <el-image
                                              style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                                              :src="sectids"
                                              :preview-src-list="item3.url.split(',')"
                                            >
                                            </el-image>
                                            </span>
                                      </div>
                                    </div>
                                  </el-descriptions-item>
                                </el-descriptions>
                            </template>
                          </div>
                        </div>
                        <!-- 采集项项为4上传照片情况 -->
                        <div class="right" v-if="item1.inputType==4">
                          <div class="imgContain" v-if="item1.url">
                            <!-- <img style="width: 100px; height: 100px" :src="thirdlys" 
                            v-for="(thirdlys,int2) in item1.url.split(',')" 
                             @click="showImg(thirdlys)"
                            :key="int2" alt=""/> -->
                            <span v-for="(thirdlys,int2) in item1.url.split(',')" :key="int2">
                            <el-image
                              style="width: 100px; height: 100px;border: 1px solid #dddddd;margin:5px;"
                              :src="thirdlys"
                              :preview-src-list="item1.url.split(',')"
                            >
                            </el-image>
                            </span>
                          </div>
                        </div>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
          
          </div>
        </div>
      </el-col>
    </el-row>
    <show-img :imgList="imgList" ref="imgList"></show-img>
  </div>
</template>
 
<script>
import {dataInfo,dataInfoget} from "@/RequestPort/checkTask/polling";
import showImg from "@/components/showImg.vue";
import {serverMap} from "@/views/Gis/utils/dict"
export default {
  name: "unresidentDetail",
  components: { showImg },
  data() {
    return {
      activeNames: 0,
      srcList:[],
      // 表单
      form:[],
      dataId:'',
      // 图片
      imgList: [],
      device:{},
      deviceLoacnion:'',
      equipmentStatusList:[]
    };
  },
  mounted(){
    this.device = JSON.parse(this.$route.query.row)
    console.log(this.device,"this.device")
    this.deviceLoacnion = JSON.parse(this.$route.query.row).location
    this.relationId = JSON.parse(this.$route.query.row).id
    if(this.$route.query.id){
      this.dataId = this.$route.query.id
       this.particulars(this.$route.query.id)
       this.equipmentinfo(this.$route.query.id,JSON.parse(this.$route.query.row))
     }
     // 管线状态
      this.equipmentStatusList = serverMap[1007]
  },
  methods: {
    particulars(val){
      let dataids
      // 事件管理传过来的
      if(this.device.eventDataId){
        dataids = this.device.eventDataId
      } else {
        // 巡检结果设备
        dataids = this.device.id
      }
      dataInfo({id:val,dataId:dataids}).then(res => {
        this.form = res.data
      })
    },
    startName(val){
      for(let i=0;i<this.equipmentStatusList.length;i++){
        if(this.equipmentStatusList[i].dictCode == val) {
          return this.equipmentStatusList[i].name
        }
      }
    },
    /**
     * 设备详情
     */
    equipmentinfo(val,row){
      dataInfoget({id:row.relationId,tableName:row.relationTable}).then(res => {
        this.device = res.data[0]
      })
    },
    print(){
      this.device.id = this.relationId
      this.$router.push({
          path: "/home/detailsPrint",
          query: { id: this.dataId,row:JSON.stringify(this.device)},
        });
    },
    /**
     * 图片地址放大
     */
    showImg(file) {
      if (file) {
          this.$refs.imgList.setdialogVisible(true);
          this.imgList = [file];
      } else {
        this.$message.error("暂无文件");
      }
    },
    detailAddress(e,index){
      if (e) {
        let center=[]
        if(typeof(e)=='object'){
          center=[Number(e[0]),Number(e[1])]
        }else{
          center=[Number(e.split(' ')[0]),Number(e.split(' ')[1])]
        }
          let map = new AMap.Map(index, {
            zoom: 15,//级别
            center: center
          });
          let marker = new AMap.Marker({
              position:center//位置
          })
          map.add(marker);//添加到地图

      } else {
        this.$message.error('无打卡位置信息')
      }
    },
    close() {
      this.$router.back(-1)
    },
  },
};
</script>
 
<style scoped lang="less">
.page {
  width: 99%;
  // height: 100%;
  height: calc(100vh - 91px);
  overflow: auto;
  margin: 10px;
  background: #fff;
  padding: 10px 20px;
  box-sizing: border-box;
  // .pdl20div {
  //   /deep/.el-descriptions {
  //     .el-descriptions__body {
  //       padding-left: 20px;
  //     }
  //   }
  // }
  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }
    .colsite {
      margin-bottom: 20px;
    }
  }
  .create {
    width: 100%;
    height: 50px;
    // background-color: burlywood;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    .create_size {
      font-size: 20px;
      font-weight: 500;
      color: #252700;
      margin-left: 20px;
    }
  }
  .box {
    width: 96%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mapLeft {
      width: 46%;
    }
    .tableRight {
      width: 100%;
      margin-top: 5px;
      margin-right: 25px;

      .btn {
        width: 210px;
        background: #0f74d5;
        border-radius: 4px;
        float: right;
        margin-right: 28%;
      }
      .title {
        padding: 10px;
        margin-bottom: 20px;
      }
      .row {
        padding: 12px 30px 0 30px;
      }
    }
  }
}
.right-part {
  padding-left: 30px;
  height: calc(100% - 50px);
  overflow: auto;
  .title {
    font-size: 16px;
    font-weight: 700;
  }
  .right-content {
    padding-left: 10px;
    .first-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      div {
        width: 50%;
        margin-bottom: 29px;
        > span {
          color: #545454;
        }
        button {
          padding: 0;
          margin-left: 40px;
        }
      }
    }
  }
  .infoCollect {
    // border: 1px solid #ECECEC;
    padding: 33px;
    .item-title::before {
      content: '| ';
      color: #7CBAFF;
    }
    /deep/.el-descriptions {
      padding: 18px 63px;
      .el-descriptions-item__label:not(.is-bordered-label) {
        color: #545454;
        width: 95px;
      }
      .el-descriptions-item__content {
        display: block;
        .ques1,.ques2 {
          line-height: 30px;
          img {
            width: 16px;
            height: 16px;
            vertical-align: text-top;
          }
        }
        .ques2 {
          padding-left: 20px;
        }
      }
    }
  }
  // .imgContain {
  //   border: 1px solid #ECEDF1;
  //   .el-image {
  //     margin: 10px ;
  //   }
  // }
  .userConfirm {
    margin-top: 24px;
    display: flex;
    .left {
      margin-right: 32px;
    }
  }
}
.outer-row {
  height: calc(100% - 50px);
  .el-col {
    height: 100%;
  }
  .el-col:first-child {
    border-right: 1px solid #f1f1f1;
  }
}
/deep/.el-descriptions__title::before,
.title::before {
  content: "| ";
  font-weight: bold;
  color: #1082ff;
}
.icon-img {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 5px;
}
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
  padding-bottom: 8px;
}
/deep/.el-descriptions-item__label{
  color:#616161
}
/deep/.el-descriptions-item__content{
  color: #000000;
}
.describ{
    color: #303133;
        font-size: 14px;
            margin-left: 17px;
  }
  .imgContain{
	margin: 0 14px;
  }
   .isPitchUpImg{
    display: flex;
    justify-content: flex-start;
    align-content: center;
  }
// .relevance{
//   background: #7CBAFF;
// }
</style>  